import { useOpenLink } from '@gain/utils/router'
import React, { ReactElement } from 'react'
import { useRouteMatch } from 'react-router-dom'

import MenuListItem, { MenuListItemProps } from './menu-list-item'

export type NavMenuListItemProps = MenuListItemProps & {
  label: string
  icon: ReactElement
  secondaryAction?: ReactElement | false
  to: string
  exact?: boolean
}

export function NavMenuListItem({ to, exact, ...props }: NavMenuListItemProps) {
  const openLink = useOpenLink()
  const match = useRouteMatch({
    exact,
    path: to,
  })

  return (
    <MenuListItem
      onClick={(event) => openLink(to, event)}
      selected={!!match}
      {...props}
    />
  )
}
