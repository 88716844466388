import { AssetListItem } from '@gain/rpc/app-model'
import { ListItemKey, ListSort, ListSortDirection } from '@gain/rpc/list-model'
import { uniq } from 'lodash'
import { useMemo } from 'react'

import { ColumnConfig } from '../../../common/virtual-table'
import { ASSET_FILTER_MAP, AssetFilterField } from '../../asset/asset-filter-bar'
import { flattenFilters, fromFilterModel, useFilterModelQueryParam } from '../../filter/filter-bar'
import { useSearchQueryParam } from '../list-view-search'
import { LIST_VIEW_ASSET_DEFAULT_SORT } from './asset-list-view'

const activeFilterToSortingColumn: Array<keyof AssetListItem> = ['relevanceRank']

// TODO this is a temporary solution to extend the ListSort type
type ExtendedListSort<Item extends object> =
  | ListSort<Item>
  | {
      direction: ListSortDirection
      field: `customerData.${string}`
    }

/**
 * useActiveFilterSorting sets the default sorting for the first column
 * that matches the active filters. It prioritizes the column's `sortFields`
 * or, if unavailable, uses the column's own `field`. This ensures that
 * sorting adapts dynamically based on the active filters, as different
 * filters may display different columns (e.g., the "Similar to" filter).
 */
export default function useActiveAssetFilterSorting(
  columns: ColumnConfig<AssetListItem>[]
): ListSort<AssetListItem>[] | undefined {
  const [filterModel] = useFilterModelQueryParam<AssetListItem, AssetFilterField>()
  const [search] = useSearchQueryParam()

  return useMemo(() => {
    const filters = fromFilterModel(filterModel, ASSET_FILTER_MAP)
    const filterKeys = uniq(flattenFilters(filters))

    // When search is active, order by fts relevance score desc
    if (search !== null) {
      return [
        {
          field: 'ftsRelevance',
          direction: 'desc',
        },
      ]
    }

    if (filterKeys.length === 0) {
      return LIST_VIEW_ASSET_DEFAULT_SORT
    }

    const column = columns.find(({ field }) => activeFilterToSortingColumn.includes(field))
    if (!column) {
      return LIST_VIEW_ASSET_DEFAULT_SORT
    }

    const sortFields = column.sortFields ? column.sortFields : [column.field]
    return sortFields.map((sortField) => ({
      direction: column.defaultSortDirection || 'desc',
      field: sortField,
    }))
  }, [columns, filterModel, search])
}
