import { LenderListItem } from '@gain/rpc/app-model'
import React, { useEffect } from 'react'

import ResourceOfflinePage from '../../common/resource-not-available'
import { useTrackEvent } from '../../features/google-analytics'

export interface LenderOfflineProps {
  lender: LenderListItem
}

export default function LenderOffline({ lender }: LenderOfflineProps) {
  const trackEvent = useTrackEvent()

  useEffect(() => {
    if (!lender) {
      return
    }

    // TODO: lender name should be mandatory
    trackEvent('Resource offline', 'Lender detail page', lender.name || 'Unknown lender')
  }, [trackEvent, lender])

  return (
    <ResourceOfflinePage
      avatarSrc={lender?.logoFileUrl}
      message={
        <>
          We found new data points and are processing them. Please come back later to view{' '}
          <strong>{lender.name || 'Unknown lender'}</strong>.
        </>
      }
      title={lender.name || 'Unknown lender'}
    />
  )
}
