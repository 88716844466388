import { AssetListItem, CustomerDataAttribute } from '@gain/rpc/app-model'
import { all } from 'axios'
import React, { useMemo } from 'react'

import { ColumnConfig, RenderHeaderParams } from '../../../common/virtual-table'
import { NameVirtualTableHeaderCell } from '../../../common/virtual-table/cells'
import {
  assetListItemTableColumns,
  assetTableColumnNames,
} from '../../asset/asset-list-item-table/asset-list-item-table-columns'
import FinancialValue from '../../financial/financial-value'
import useListViewColumnVisibilityModel from '../use-list-view-column-visibility-model'

const ACTIVE_SEARCH_COLUMNS = ['ftsHeadline']

export default function useAssetListViewColumns(
  activeFilterColumns: (keyof AssetListItem)[],
  customerDataAttributes: CustomerDataAttribute[],
  maxLimit?: number,
  maxLimitTooltip?: string
): ColumnConfig<AssetListItem>[] {
  const columnVisibilityModel = useListViewColumnVisibilityModel(
    'asset',
    assetTableColumnNames,
    activeFilterColumns,
    ACTIVE_SEARCH_COLUMNS
  )

  return useMemo(() => {
    // Override the default name column with maxLimit props
    const nameColumn = {
      ...assetListItemTableColumns[0],
      renderHeader: (params: RenderHeaderParams<AssetListItem, 'name'>) => (
        <NameVirtualTableHeaderCell
          maxLimit={maxLimit}
          maxLimitTooltip={maxLimitTooltip}
          {...params}
        />
      ),
    } as ColumnConfig<AssetListItem>

    // Customer data columns
    const customerDataColumns = customerDataAttributes.map((attribute) => ({
      field: `customerData.${attribute.attributeName}`,
      headerName: attribute.attributeName,
      align: 'right',
      width: 200,
      renderCell: ({ row }) => {
        if (row.customerData && attribute.attributeName in row.customerData) {
          return <FinancialValue amount={row.customerData[attribute.attributeName]} />
        }
        return <FinancialValue />
      },
    }))

    // Only return visible columns
    return [nameColumn, ...customerDataColumns, ...assetListItemTableColumns.slice(1)].filter(
      ({ field }) => columnVisibilityModel[field] || field.startsWith('customerData.')
    ) as ColumnConfig<AssetListItem>[] // TODO fix typing
  }, [columnVisibilityModel, customerDataAttributes, maxLimit, maxLimitTooltip])
}
