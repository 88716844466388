import { useLenderListItem } from '@gain/api/app/hooks'
import { LenderIcon } from '@gain/components/icons'
import { SlotHome, SlotName } from '@gain/components/slot'
import { useIsXs } from '@gain/utils/responsive'
import { styled } from '@mui/material/styles'
import { generatePath, Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom'

import Loading from '../../common/loading'
import ProfileTabBar, { ProfileTabContainer, useActiveTab } from '../../common/profile-tab-bar'
import { useTrackPageView } from '../../features/planhat/planhat-hooks'
import MobilePageHeader from '../../layout/mobile-page-header'
import NotFound from '../not-found'
import LenderOffline from './lender-offline'
import { LENDER_SUMMARY_PATH, LenderPageParams, RouteLenderTabs } from './lender-path'
import RouteSummary from './route-summary'

export function useConferenceTabs() {
  const params = useParams<LenderPageParams>()

  return [
    {
      label: 'Summary',
      path: generatePath(LENDER_SUMMARY_PATH, params),
      value: RouteLenderTabs.Summary,
    },
  ]
}

const StyledProfileTabContainer = styled(ProfileTabContainer)({
  flex: 1,
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
})

export default function RouteLender() {
  const params = useParams<LenderPageParams>()
  const history = useHistory()
  const isXs = useIsXs()

  const swrLender = useLenderListItem(parseInt(params.id, 10))

  const tabs = useConferenceTabs()
  const activeTab = useActiveTab(tabs)

  useTrackPageView('lender', {
    id: parseInt(params.id, 10),
    tab: activeTab,
    requireTab: true,
  })

  if (swrLender.loading) {
    return <Loading />
  }

  if (!swrLender.data) {
    return <NotFound />
  }

  if (!swrLender.data.live) {
    return <LenderOffline lender={swrLender.data} />
  }

  const summaryPath = generatePath(LENDER_SUMMARY_PATH, params)
  // TODO: get url: const primaryUrl = swrLender.data?.urls.find((url) => url.url)?.url

  return (
    <>
      {isXs && <MobilePageHeader title={swrLender.data.name} />}

      <ProfileTabBar
        activeTab={activeTab}
        defaultIcon={!isXs ? LenderIcon : undefined}
        logoFileUrl={swrLender.data.logoFileUrl}
        onLogoClick={() => history.push(summaryPath)}
        // TODO: get url: subtitleUrl={primaryUrl}
        tabActions={<SlotHome slotName={SlotName.Tabs} />}
        tabs={tabs}
        title={<>{swrLender.data.name}</>}
        replaceUrl
        sticky
      />

      <StyledProfileTabContainer
        maxWidth={'lg'}
        stickyVariant={'tabs'}
        disableGuttersTop>
        <Switch>
          <Route path={LENDER_SUMMARY_PATH}>
            <RouteSummary />
          </Route>

          <Route path={'*'}>
            <Redirect to={generatePath(LENDER_SUMMARY_PATH, params)} />
          </Route>
        </Switch>
      </StyledProfileTabContainer>
    </>
  )
}
